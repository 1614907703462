<template>
  <div class="box">
    <div class="register">
      <p class="title">注册账号 | SIGN UP</p>
      <div>
        <p class="inputT">手机号</p>
        <el-input
          clearable
          placeholder="请输入手机号"
          v-model="input1"
          size="small"
          @input="hh1"
          @blur="hh1"
        >
        </el-input>
        <div class="tip" v-show="isone">{{ tips1 }}</div>
      </div>
      <div>
        <p class="inputT">验证码</p>
        <el-input
          clearable
          size="small"
          placeholder="请输入验证码"
          v-model="input2"
          class="code"
          @input="hh2"
          @blur="hh2"
        >
        </el-input>
        <el-button
          style="width: 101px"
          size="small"
          :disabled="disabled"
          type="primary"
          @click="getCode"
          >{{ codenum }}</el-button
        >
        <div class="tip" v-show="istwo">{{ tips2 }}</div>
      </div>
      <div>
        <p class="inputT">昵称</p>
        <el-input
          clearable
          placeholder="请输入昵称"
          v-model="input3"
          size="small"
          @input="hh3"
          @blur="hh3"
        >
        </el-input>
        <div class="tip" v-show="isthree">{{ tips3 }}</div>
      </div>
      <div>
        <p class="inputT">密码</p>
        <el-input
          clearable
          placeholder="请输入密码"
          v-model="input4"
          size="small"
          @input="hh4"
          @blur="hh4"
          type="password"
        >
        </el-input>
        <div class="tip" v-show="isfour">{{ tips4 }}</div>
      </div>
      <div>
        <p class="inputT">确认密码</p>
        <el-input
          clearable
          placeholder="请确认密码"
          v-model="input5"
          size="small"
          @input="hh5"
          @blur="hh5"
          type="password"
        >
        </el-input>
        <div class="tip" v-show="isfive">{{ tips5 }}</div>
      </div>
      <div class="clearfix" style="margin: 10px 0">
        <el-checkbox
          v-model="checked"
          style="margin-top: 10px; margin-right: 60px"
          @change="hh6"
          >注册即同意《用户服务协议》</el-checkbox
        >
        <div class="tip" v-show="issix">请阅读并同意我们的服务条款</div>
      </div>
      <div class="clearfix" style="height: 50px; line-height: 50px">
        <el-button
          type="primary"
          class="fl"
          style="width: 50%; margin-top: 10px"
          @click="handleReg"
          >立即注册</el-button
        >
        <span class="btn fr" @click="$router.push('/login')"
          >已有账号，立即登录 ></span
        >
      </div>
    </div>
    <!-- <div class="bottom">
      <p>版权及最终解释权归山东大课堂信息技术有限公司所有</p>
      <p>
        Copy right@2018 山东大课堂信息技术有限公司 all Rights Reserved 版权所有
      </p>
    </div> -->
  </div>
</template>

<script>
import URL from "../../api/index";
export default {
  data() {
    return {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      checked: true,
      tips1: "请输入手机号",
      tips2: "请输入验证码",
      tips3: "请输入昵称",
      tips4: "请输入密码",
      tips5: "请确认密码",
      issix: false,
      isfive: false,
      isfour: false,
      isthree: false,
      istwo: false,
      isone: false,
      disabled: false,
      codenum: "获取验证码",
      countdown: 60,
    };
  },
  components: {},
  mounted() {},
  methods: {
    hh1() {
      if (!this.input1) {
        this.isone = true;
      } else {
        this.isone = false;
        var re =
          /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        if (re.test(this.input1)) {
          this.isone = false;
        } else {
          this.isone = true;
          this.tips1 = "请输入正确格式的手机号";
        }
      }
    },
    hh2() {
      if (!this.input2) {
        this.istwo = true;
      } else {
        this.istwo = false;
      }
    },
    hh3() {
      if (!this.input3) {
        this.isthree = true;
      } else {
        this.isthree = false;
      }
    },
    hh4() {
      if (!this.input4) {
        this.isfour = true;
      } else {
        this.isfour = false;
        var re = /^[\w!-@#$%^&*]{6,20}$/;
        if (re.test(this.input4)) {
          this.isfour = false;
        } else {
          this.isfour = true;
          this.tips4 = "请输入6到20位字母或数字的密码";
        }
      }
    },
    hh5() {
      if (!this.input5) {
        this.isfive = true;
      } else {
        this.isfive = false;
        if (this.input4 == this.input5) {
          this.isfive = false;
        } else {
          this.isfive = true;
          this.tips5 = "密码不一致";
        }
      }
    },
    hh6() {
      if (!this.checked) {
        this.issix = true;
      } else {
        this.issix = false;
      }
    },
    timer() {
      if (this.countdown > 0) {
        this.disabled = true;
        this.countdown--;
        this.codenum = "重新发送(" + this.countdown + ")";
        setTimeout(this.timer, 1000);
      } else {
        this.countdown = 0;
        this.codenum = "获取验证码";
        this.disabled = false;
      }
    },
    getCode() {
      var data_code = {
        mobile: this.input1,
      };
      if (!this.input1) {
        this.isone = true;
      }
      if (!this.isone) {
        this.$post(URL.getvcode, data_code).then((res) => {
          // console.log(res);
          if (res.state == 0) {
            this.$message({
              message: res.msg,
              type: "success",
            });

            this.timer();
          } else {
            this.$message(res.msg);
          }
        });
      }
      this.countdown = 60;
    },
    handleReg() {
      var data = {
        name: this.input3,
        password: this.input4,
        mobile: this.input1,
        code: this.input2,
      };
      if (!this.input1) {
        this.isone = true;
      }

      if (!this.input2) {
        this.istwo = true;
      }

      if (!this.input3) {
        this.isthree = true;
      }

      if (!this.input4) {
        this.isfour = true;
      }

      if (!this.input5) {
        this.isfive = true;
      }

      if (
        this.checked &&
        !this.isone &&
        !this.istwo &&
        !this.isthree &&
        !this.isfour &&
        !this.isfive
      ) {
        this.$post(URL.getregister, data).then((res) => {
          // console.log(res);
          if (res.state == 0) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push("/login");
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../../assets/image/img.png");
  background-position: 0 -100px;

  .register {
    border-radius: 8px;
    background-color: #fff;
    position: absolute;
    top: 12%;
    right: 38%;
    padding: 30px 40px;
    .title {
      font-size: 26px;
      color: #6c6c6d;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .inputT {
    padding-top: 10px;
  }
  .tip {
    font-size: 12px;
    color: red;
  }
  .el-checkbox /deep/ .el-checkbox__label {
    font-size: 12px !important;
  }
  .btn {
    font-size: 12px !important;
    cursor: pointer;
    margin-top: 5px;
    color: #639edd;
  }
  .el-input {
    width: 340px;
    margin: 6px 0;
  }

  .code {
    width: 220px;
    margin-right: 20px;
  }

  .bottom {
    position: absolute;
    text-align: center;
    bottom: 40px;
    left: 40%;
    color: #fff;
    font-size: 12px;
    p {
      margin-bottom: 20px;
    }
  }
}
</style>
